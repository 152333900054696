.gradient {
  background: linear-gradient(135deg,
      rgb(30, 187, 240) 30%,
      rgb(57, 223, 170) 100%);
}

.gradient.gradient--text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grecaptcha-badge {
  visibility: hidden;
}

.react-pdf__Page {
  background-color: #eee !important;
  padding: 16px 8px;
  border-bottom: 1px dashed #ccc;
}

.react-pdf__Page:last-child {
  border-bottom: none;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}